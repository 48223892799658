<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card>
      <v-card-title>
        <div>
          <div>Payment card</div>
          <i v-if="user.card_brand" class="body-2 text--secondary">
            Current: {{ user.card_brand }} **** **** ****
            {{ user.card_last_four }}
          </i>
          <i v-else>No payment card associated</i>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="p-2 mt-2" style="border: 1px solid #e3e3e3">
          <PaymentCard
            class="stripe-card"
            ref="stripeCardElement"
            v-if="settings.stripe_key"
            @cardChange="cardChange"
            :stripe="stripe"
          >
          </PaymentCard>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="createCardData()"
          :disabled="!card"
          :loading="changeCardLoading"
        >
          {{ user.card_brand ? "Change" : "Add" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaymentCard from "@/components/common/forms/PaymentCard.vue";

export default {
  components: { PaymentCard },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    changeCardLoading: false,
    stripePaymentIntentClientSecret: null,
    cardName: null,
    stripe: null,
    card: null
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapState("settings", ["settings"]),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    ...mapActions("core/auth", ["getUserFromApi"]),
    ...mapActions("core", ["paymentCards", "paymentIntent"]),
    createCardData() {
      this.changeCardLoading = true;
      const options = {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.cardName
          }
        }
      };
      this.stripe
        .confirmCardSetup(this.stripePaymentIntentClientSecret, options)
        .then(result => {
          if (result.error) {
            this.handleError(result.error);
          } else {
            this.handleCardRequest(result.setupIntent.payment_method);
          }
        });
    },
    handleCardRequest(method) {
      this.paymentCards({ token: method }).then(
        () => {
          this.setSnackSuccess("Saved");
          this.changeCardLoading = false;
          this.showDialog = false;
          this.card = null;
          this.getUserFromApi();
        },
        error => {
          this.handleError(error);
        }
      );
    },
    // eslint-disable-next-line no-unused-vars
    handleError(error) {
      this.setSnackError("Something went wrong");
      this.changeCardLoading = false;
    },
    cardChange(event) {
      this.card = event;
    }
  },
  created() {
    this.cardName = this.user.first_name + " " + this.user.last_name;
    this.stripe = window.Stripe(this.settings.stripe_key);
    // Get client secret
    this.paymentIntent().then(
      response => {
        this.stripePaymentIntentClientSecret = response.secret;
      },
      error => {
        this.handleError(error);
        this.setSnackError("Something went wrong");
      }
    );
  }
};
</script>
