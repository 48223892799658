<template>
  <v-card>
    <invoice-data-table
      :loading="loading"
      :items="orderedInvoices"
    ></invoice-data-table>

    <div class="text-center">
      <v-btn
        v-if="invoices.length > 1 && hasMore"
        :loading="loading"
        text
        small
        color="primary"
        @click="loadMoreInvoices()"
        >Show more</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import InvoiceDataTable from "../../data-tables/InvoiceDataTable";
import { mapActions } from "vuex";

export default {
  components: { InvoiceDataTable },
  data: () => ({
    loading: false,
    invoices: [],
    hasMore: true
  }),
  computed: {
    orderedInvoices() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.invoices.sort((a, b) => (a.date < b.date ? 1 : -1));
    }
  },
  methods: {
    ...mapActions("core", ["getInvoices"]),

    loadMoreInvoices() {
      this.loading = true;

      // Request params
      let params = {
        limit: 25
      };

      if (this.orderedInvoices.length > 0) {
        let lastIndex = this.orderedInvoices.length - 1;
        let lastInvoice = this.orderedInvoices[lastIndex];

        params.starting_before = lastInvoice.date;
      }
      this.getInvoices(params).then(response => {
        this.loading = false;
        // If length is not equal to limit there's no more invoices
        if (response.length !== params.limit) {
          this.hasMore = false;
        }

        for (let index in response) {
          let newItem = response[index];
          let exists = this.invoices.filter(
            item => item.invoice_key === newItem.invoice_key
          );

          if (exists.length === 0) {
            this.invoices.push(newItem);
          }
        }
      });
    }
  },
  created() {
    this.loadMoreInvoices();
  }
};
</script>
